/* jshint esversion: 6 */

$('#modalErrorXHR .close').on('click', function() {
    $('#modalErrorXHR').hide();
    $('#modalErrorXHRmsg').html('');
});

$('#modalErrorXHR .btn-close').on('click', function() {
    $('#modalErrorXHR').hide();
    $('#modalErrorXHRmsg').html('');
});

xhook.after(function(request, response) {
    var status = response.status;
    var method = request.method;

    var is404 = function() {
        var msg = '';

        if (request.url.indexOf('resources/dte-types/') != -1) {
            msg = 'No se pueden cargar Documentos, intentelo mas tarde';
            msg += '<br>';
        }
        if (request.url.indexOf('pagos/participantes_operables') != -1) {
            msg = 'No se pueden cargar Participantes.';
            msg += '<br>';
        }
        if (request.url.indexOf('resources/banks') != -1) {
            msg = 'No se pueden cargar Bancos.';
            msg += '<br>';
        }
        if (request.url.indexOf('resources/transaction-types') != -1) {
            msg = 'No se pueden cargar tipos de transacción.';
            msg += '<br>';
        }
        if (request.url.indexOf('resources/instructions') != -1) {
            msg = 'No se pueden cargar Instrucciones de Pago.';
            msg += '<br>';
        }

        if (request.url.indexOf('resources/payment-execution-payment-instructions') != -1) {
            msg = 'No se pueden cargar Pagos asociados a esta Instrucción.';
            msg += '<br>';
        }
        if (request.url.indexOf('operations/dtes/create') != -1) {
            msg = 'No se pueden crear Documento.';
            msg += '<br>';
        }
        if (request.url.indexOf('operations/dtes/') != -1) {
            if (request.url.indexOf('/edit/') != -1) {
                msg = 'No se pueden guardar los cambios para este Documento.';
            }
            if (request.url.indexOf('/delete/') != -1) {
                msg = 'No se eliminar este Documento.';
            }
            msg += '<br>';
        }

        if (request.url.indexOf('operations/payments/') != -1) {
            if (request.url.indexOf('/create/') != -1) {
                msg = 'No se crear esta Instrucción de Pago.';
            }
            if (request.url.indexOf('/delete/') != -1) {
                msg = 'No se eliminar esta Instrucción de Pago.';
            }
            msg += '<br>';
        }

        $('#modalErrorXHR').show();
        $('#modalErrorXHRmsg').noty({
            text: msg,
            closeWith: ['button'],
        });

    };

    var isErrorUpload = function() {
        console.table(request, response);
        var msg = 'No se pueden cargar Archivo.';
        msg += '<br>';

        $('#modalErrorXHR').show();
        var n = $('#modalErrorXHRmsg').noty({
            text: msg,
            closeWith: ['button'],
        });
    };

    var isErrorTimeOut = function() {
        console.table(request, response);
        var msg = 'Servicio no responde, se ha superado el tiempo de espera.';
        msg += '<br>';

        $('#modalErrorXHR').show();
        $('#modalErrorXHRmsg').noty({
            text: msg,
            closeWith: ['button'],
        });
    };

    if ((status == 404 || status === 0) && method.toLowerCase() != 'put') {
        is404();
    }

    if ((status != 201 || status === 0) && method.toLowerCase() == 'put') {
        isErrorUpload();
    }
    // if(response.status == 0){
    //   isErrorTimeOut();
    // }
});

var PPagos = function() {
    this.version = '0.1.0';
    this.template = {};
    this.view = {};
    this.config = {};
    this.helper = {};
    this.config.vueDelimiters = ['${', '}'];

    this.http_headers_names = {
        csrftoken: 'X-CSRFToken',
        disposition: 'Content-Disposition',
    };

    this.url = {
        editable_dte: function(opt) {
            var options = opt || {};
            options.redirect = opt.redirect || false;
            var url = '/pagos/documento/' + options.id + '/';
            if (options.redirect) {
                window.location.href = url;
                return;
            }
            return url;
        },
        api_get_operable_participant: function() {
            return '/pagos/participantes_operables/';
        },
        api_get_bank: function(options) {
            options = options || '';
            return '/api/v1/resources/banks/?format=json' + options;
        },
        api_get_transaction_type: function(options) {
            options = options || '';
            return '/api/v1/resources/transaction-types/?format=json' + options;
        },
        api_instruction: function() {
            return '/api/v1/resources/instructions/';
        },
        api_participant: function() {
            return '/api/v1/resources/participants/';
        },
        api_payment_matrices: function() {
            return '/api/v1/resources/payment-matrices/';
        },
        api_billing_windows: function() {
            return '/api/v1/resources/billing-windows/';
        },
        api_billing_types: function() {
            return '/api/v1/resources/billing-types/';
        },
        api_participant_by_payment: function() {
            return '/pagos/participantes_en_pago/';
        },
        api_get_dtes: function(options) {
            options = options || '';
            return '/api/v1/resources/dtes/' + options;
        },
        api_get_dte_types: function(options) {
            options = options || '';
            return '/api/v1/resources/dte-types/' + options;
        },
        api_get_payment_to_instruction: function(id) {
            return '/api/v1/resources/payment-execution-payment-instructions/?instruction=' + id;
        },
        api_send_auxiliary_files: function(options) {
            options = options || '';
            return '/api/v1/resources/auxiliary-files/' + options;
        },
        api_get_agents: function() {
            return '/api/v1/resources/agents/';
        },
        api_create_dte: function(options) {
            options = options || '';
            return '/api/v1/operations/dtes/create/' + options;
        },
        api_update_dte: function(id) {
            return '/api/v1/operations/dtes/' + id + '/edit/';
        },
        api_delete_dte: function(id) {
            return '/api/v1/operations/dtes/' + id + '/delete/';
        },
        api_delete_payment: function(id) {
            return '/api/v1/operations/payments/' + id + '/delete/';
        },
        api_payment_create: function() {
            return '/api/v1/operations/payments/create/';
        },
        api_get_vat: function(options) {
            options = options || '';
            return '/api/v1/resources/vat/' + options;
        },
        api_regenerate_sftp: function(id) {
            return '/api/v1/resources/participants/'+ id +'/regenerate_sftp/';
        },
        instruction_view: function(instruction_id) {
            return '/pagos/instrucciones/' + instruction_id + '/';
        },
        payments_matrix_view: function(id) {
            return '/pagos/cuadros/' + id;
        },
        document_view: function(id) {
            return '/pagos/documento/' + id + '/';
        },
        goto_payment_list: function() {
            window.location.href = '/pagos/';
        },
        api_get_periods: function() {
            return '/api/v2/resources/periods/';
        },

        // TEMPLATES
        template_bulk_dte_creditor: function() {
            return '/carga-masiva/dtes/plantilla/creditor';
        },
        template_bulk_dte_debtor: function() {
            return '/carga-masiva/dtes/plantilla/debtor';
        },
        template_bulk_payment: function() {
            return '/carga-masiva/pagos/plantilla/';
        },
        template_bulk_payment_dte: function() {
            return '/carga-masiva/pagos/plantilla/dtes/';
        },
        template_bulk_payment_instruction: function() {
            return '/carga-masiva/pagos/plantilla/instrucciones/';
        },

        // API BULKLOAD CREATE
        api_bulk_dte_debtor_create: function() {
            return '/api/v1/operations/debtor-dte-load-jobs/create/';
        },
        api_bulk_dte_creditor_create: function() {
            return '/api/v1/operations/creditor-dte-load-jobs/create/';
        },
        api_bulk_payment_create: function() {
            return '/api/v1/operations/payment-execution-load-jobs/create/';
        },

        // API BULKLOAD PUBLISH
        api_bulk_dte_debtor_publish: function(id) {
            return '/api/v1/operations/debtor-dte-load-jobs/' + id + '/publish/';
        },
        api_bulk_dte_creditor_publish: function(id) {
            return '/api/v1/operations/creditor-dte-load-jobs/' + id + '/publish/';
        },
        api_bulk_payment_publish: function(id) {
            return '/api/v1/operations/payment-execution-load-jobs/' + id + '/publish/';
        },

        // API BULKLOAD LIST
        api_get_bulk_dte_creditor: function() {
            return '/api/v1/resources/creditor-dte-load-jobs/';
        },
        api_get_bulk_dte_debtor: function() {
            return '/api/v1/resources/debtor-dte-load-jobs/';
        },
        api_get_bulk_payments: function() {
            return '/api/v1/resources/payment-execution-load-jobs/';
        },

        // API BULKLOAD DETAIL
        api_detail_bulk_dte_creditor: function(id) {
            return '/api/v1/resources/creditor-dte-load-jobs/' + id + '/';
        },
        api_detail_bulk_dte_debtor: function(id) {
            return '/api/v1/resources/debtor-dte-load-jobs/' + id + '/';
        },
        api_detail_bulk_payment: function(id) {
            return '/api/v1/resources/payment-execution-load-jobs/' + id + '/';
        },

        // GO TO
        goto_detail_bulk_dte_creditor: function(id) {
            return '/carga-masiva/dtes/acreedor/detalle/?id=' + id;
        },
        goto_detail_bulk_dte_debtor: function(id) {
            return '/carga-masiva/dtes/deudor/detalle/?id=' + id;
        },
        goto_detail_bulk_payment: function(id) {
            return '/carga-masiva/pagos/detalle/?id=' + id;
        },
        goto_bulk_dte_creditor: function() {
            return '/carga-masiva/dtes/acreedor/';
        },
        goto_bulk_dte_debtor: function() {
            return '/carga-masiva/dtes/deudor/';
        },
        goto_bulk_payment: function() {
            return '/carga-masiva/pagos/';
        },

        api_get_auxiliary_file: function(id) {
            return '/api/v1/resources/auxiliary-files/' + id + '/';
        },
        api_get_user: function(id) {
            return '/api/v1/resources/user/' + id + '/';
        },
    };

    this.add = function(type, name, object) {
        this[type][name] = object;
    };
};

window.ppagos = new PPagos();

/** CONTROL TO
** cdec_ppagos/apps/participants/templates/participants/holdings.html
*/
var addTextFirstLive = function(cssClass,text) {

    function _watch() {
        var tds = document.querySelectorAll(cssClass);
        [].forEach.call(tds, function(td) {
            // do whatever
            if (td.innerText.indexOf(text) == -1) {
                var txt =  text + ' ' + td.innerText;
                td.innerText = txt;
            }
        });
    }

    setInterval(function() {
        _watch();
    }, 500);

};


var holdings = function() {
    $('#participant-table').bootstrapTable({
        pagination: 'true',
        pageList: '[5, 25, 50, 100, 200]',
        pageSize: '25',
    });
};

$.fn.datepicker.defaults.language = 'es';
